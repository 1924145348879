import React, { useState, useEffect } from "react";
import "./FirstForm.css";
// import axios from "axios";
import Plans from "./Plans.jsx";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { BASE_URL } from "../../../apiConfig";
import ConfirmationPopUp from "./ConfirmationPopUp";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function FirstForm() {
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [categoryGroup, setCategoryGroup] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [saveSubcats, setSaveSubcats] = useState([]);
  const [saveCats, setSaveCats] = useState([]);
  const [activeCatId, setActiveCatId] = useState("");

  const [selectedItem, setSelectedItem] = useState("");
  const [selectedItemId, setSelectedItemId] = useState("");
  const navigate = useNavigate();

  const [firstForm, setFirstForm] = useState(true);
  const [planForm, setPlanForm] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");

  const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false);

  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [partnerName, setPartnerName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [subcategoriesSelected, setSubcategoriesSelected] = useState([]);
  const [address, setAddress] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [instaLink, setInstaLink] = useState("");
  const [fbLink, setFbLink] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [pincode, setPincode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [overview, setOverview] = useState("");
  const [startingPrice, setStartingPrice] = useState("");
  const [maximumPrice, setMaximumPrice] = useState("");
  const [businessYears, setBusinessYears] = useState("");
  const [projectsNum, setProjectsNum] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const [selectedFile5, setSelectedFile5] = useState(null);
  const [selectedFile6, setSelectedFile6] = useState(null);

  // console.log(categoriesSelected);

  const handleFileChange1 = (e) => {
    const fileInput = e.target;
    if (validateFile(fileInput, 2, "image")) {
      setSelectedFile1(fileInput.files[0]);
    }
  };

  const handleFileChange2 = (e) => {
    const fileInput = e.target;
    if (validateFile(fileInput, 2, "image")) {
      setSelectedFile2(fileInput.files[0]);
    }
  };

  const handleFileChange3 = (e) => {
    const fileInput = e.target;
    if (validateFile(fileInput, 2, "image")) {
      setSelectedFile3(fileInput.files[0]);
    }
  };

  const handleFileChange4 = (e) => {
    const fileInput = e.target;
    if (validateFile(fileInput, 2, "image")) {
      setSelectedFile4(fileInput.files[0]);
    }
  };

  const handleFileChange5 = (e) => {
    const fileInput = e.target;
    if (validateFile(fileInput, 10, "video")) {
      setSelectedFile5(fileInput.files[0]);
    }
  };
  const handleFileChange6 = (e) => {
    const fileInput = e.target;
    if (validateFile(fileInput, 10, "video")) {
      setSelectedFile6(fileInput.files[0]);
    }
  };

  console.log(saveSubcats);

  const handleClickOpen = () => {
    setOpenAddCategoryModal(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenAddCategoryModal(false);
    }
  };

  ///////////////////////////////////////////////////
  useEffect(() => {
    //fetch all categories and subcategories from backend
    const fetchItems = async () => {
      try {
        const categoriesResponse = await fetch(`${BASE_URL}/getcategory`);

        if (!categoriesResponse.ok) {
          throw new Error("Error fetching items");
        }

        const data1 = await categoriesResponse.json();

        setCategories(data1.category);

        //fetch all subcategories from backend
        const subcategoriesResponse = await fetch(`${BASE_URL}/subcategories`);

        if (!subcategoriesResponse.ok) {
          throw new Error("Error fetching items");
        }

        const data2 = await subcategoriesResponse.json();
        setSubcategories(data2);
        console.log(data2);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };
    fetchItems();
  }, []);

  const handleCategoryChange = (event) => {
    // setSaveSubcats([])
    setSelectedSubcategories([]);
    const selectedItemId = event.target.value;

    setSelectedItemId(selectedItemId);
    const selectedItem = categories.find((item) => item._id === selectedItemId);

    setSubcategories(selectedItem.subcategories);
    console.log(subcategories);
    setSelectedCategory(event.target.value);
  };

  const handleSubcategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSubcategories(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  console.log(saveCats);
  const saveCatId = (catId) => {
    setActiveCatId(catId);
  };

  const saveSubcatsHandler = (e, subcatId) => {
    console.log("hello");
    console.log(e.target.checked);
    console.log(subcatId);

    if (e.target.checked === true) {
      setSaveSubcats((prevValue) => [...prevValue, subcatId]);
    } else if (e.target.checked === false) {
      setSaveSubcats((prevValue) => {
        return prevValue.filter((id) => {
          return id !== subcatId;
        });
      });
    }
  };

  const handleCategoriesSelection = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenAddCategoryModal(false);
    }

    setSaveCats((prevValue) => [...prevValue, activeCatId]);
    setSelectedItem("");
    // setSubcategoriesSelected([]);

    const finalCategory = categories.find((cat) => {
      return cat._id === selectedCategory;
    });

    setCategoryGroup((prevValue) => [
      ...prevValue,
      {
        categoryName: finalCategory.categoryName,
        subCatNames: [selectedSubcategories],
      },
    ]);

    setSelectedCategory("");
    setSelectedSubcategories([]);
  };

  ///////////////////////////////////////////////////////////

  function planFormHandler() {
    setPlanForm(true);
    setFirstForm(false);
  }

  function planSelectHandler(plan) {
    setSelectedPlan(plan);
    setPlanForm(false);
  }

  // VALIDATE FILSE SIZE UPLOADED BY VENDOR
  function validateFile(fileInput, maxSize, fileType) {
    const file = fileInput.files[0];
    const fileSize = file.size / 1024 / 1024; // Convert to MB

    if (fileSize > maxSize) {
      alert(`The ${fileType} size exceeds the limit of ${maxSize}MB.`);
      fileInput.value = ""; // Clear the file input
      return false;
    }

    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("categoriesSelected", saveCats);
      formData.append("subcategoriesSelected", saveSubcats);
      formData.append("partnerName", partnerName);
      formData.append("companyName", companyName);
      formData.append("planSelected", selectedPlan);
      formData.append("address", address);
      formData.append("pincode", pincode);
      formData.append("phoneNumber", phoneNumber);
      formData.append("whatsappNumber", whatsappNumber);
      formData.append("city", city);
      formData.append("email", email);
      formData.append("additionalInfo", additionalInfo);
      formData.append("overview", overview);
      formData.append("password", password);
      formData.append("confirmPassword", confirmPassword);
      formData.append("websiteURL", websiteURL);
      formData.append("instaLink", instaLink);
      formData.append("fbLink", fbLink);
      formData.append("gstNumber", gstNumber);
      formData.append("startingPrice", startingPrice);
      formData.append("maximumPrice", maximumPrice);
      formData.append("businessYears", businessYears);
      formData.append("projectsNum", projectsNum);
      console.log(formData);

      formData.append("image1", selectedFile1);
      formData.append("image2", selectedFile2);
      formData.append("image3", selectedFile3);
      formData.append("image4", selectedFile4);
      formData.append("video1", selectedFile5);
      formData.append("video2", selectedFile6);

      // Send a POST request to the server with the file attached
      const response = await fetch(`${BASE_URL}/vendor/signup`, {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      if (!response.ok) {
        toast.error("Failed to register vendor!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        throw new Error("Failed to register vendor");
      } else {
        toast.success("Registration successful!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }

      // console.log("vendor registered successfully!");
      // setConfirmationModal(true);
      // setTimeout(() => {
      //   navigate("/vendorregistration/payment");
      // }, 20000);
      // setTimeout(() => {
      //   navigate("/payment");
      // }, 200);
      //After successful registration, initiate the PhonePe payment
      try {
        const response = await fetch(`${BASE_URL}/phonepe/payment`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        });

        const paymentResponse = await response.json();

        if (paymentResponse.success === false) {
          toast.error(paymentResponse.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        }

        console.log(paymentResponse);
        console.log(paymentResponse.data.instrumentResponse.redirectInfo.url);
        const paymentUrl =
          paymentResponse.data.instrumentResponse.redirectInfo.url;

        // Redirect the user to the PhonePe payment page
        window.location.href = paymentUrl;
      } catch (paymentError) {
        console.error("Error initiating payment:", paymentError);
        toast.error("Error Initiating Payment!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }

      // try {
      //   const paymentResponse = await axios.post(
      //     `${BASE_URL}/phonepe/payment`,
      //     {},
      //     { withCredentials: true }
      //   );

      //   if (paymentResponse.data.success === false) {
      //     toast.error(paymentResponse.data.message, {
      //       position: "top-center",
      //       autoClose: 5000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: false,
      //       progress: undefined,
      //       theme: "colored",
      //     });
      //   }
      //   console.log(paymentResponse.data);
      //   console.log(
      //     paymentResponse.data.data.instrumentResponse.redirectInfo.url
      //   );
      //   const paymentUrl =
      //     paymentResponse.data.data.instrumentResponse.redirectInfo.url;

      //   // Redirect the user to the PhonePe payment page
      //   window.location.href = paymentUrl;
      // } catch (paymentError) {
      //   console.error("Error initiating payment:", paymentError);
      //   toast.error("Error Initiating Payment!", {
      //     position: "top-center",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: false,
      //     progress: undefined,
      //     theme: "colored",
      //   });
      // }
    } catch (e) {
      console.log("something is wrong:", e);
    }

    // navigate('/vendor/login')
  };

  return (
    <>
      <ConfirmationPopUp
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
      />
      <div className={planForm ? "plans-section" : "vendorRegistration"}>
        {/* <h1>Business Details</h1>
      <hr /> */}
        <form onSubmit={handleSubmit}>
          {/* FORM FIRST PART */}
          {firstForm && (
            <>
              <h1 style={{ textAlign: "center" }}>Business Details</h1>
              <hr />
              <p style={{ color: "grey", fontStyle: "italic" }}>
                All fields marked with an asterisk (*) are mandatory.
              </p>
              <div className="firstForm">
                <div className="inputBox">
                  <label htmlFor="nameOfThePartner">Name of the Partner*</label>
                  <input
                    type="text"
                    id="nameOfThePartner"
                    name="partnerName"
                    required
                    onChange={(e) => setPartnerName(e.target.value)}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="nameOfTheCompany">Name of the Company*</label>
                  <input
                    type="text"
                    id="nameOfTheCompany"
                    name="companyName"
                    required
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="fullAddress">Full Address*</label>
                  <input
                    type="text"
                    id="fullAddress"
                    value={address}
                    name="address"
                    required
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="city">City*</label>
                  <input
                    type="text"
                    id="city"
                    value={city}
                    name="city"
                    required
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="pinCode">Pin Code*</label>
                  <input
                    type="number"
                    id="pinCode"
                    value={pincode}
                    name="pincode"
                    required
                    onChange={(e) => setPincode(e.target.value)}
                  />
                </div>

                <div className="inputBox"></div>
                <div className="allCatWrapper">
                  Service(s) offered*
                  <div className="allCatDivWrapper">
                    <div className="allCategoriesDiv">
                      {categoriesSelected.length < 0 ? (
                        <p>Choose Categories...</p>
                      ) : (
                        <ol>
                          {categoryGroup.map((group) => (
                            <li>
                              <p>
                                {group.categoryName}:
                                {group.subCatNames.join(", ")}
                              </p>
                            </li>
                          ))}
                        </ol>
                      )}
                    </div>

                    <button type="button" onClick={handleClickOpen}>
                      Add Categories +
                    </button>
                  </div>
                  <p style={{ margin: "5px", color: "grey" }}>
                    Select any category and then select the corresponding
                    sub-categories. <br />
                    Please note, if you wish to choose multiple categories,
                    you'll have to pay subscription fees accordingly (per
                    category).
                  </p>
                </div>

                <Dialog
                  disableEscapeKeyDown
                  open={openAddCategoryModal}
                  onClose={handleClose}
                >
                  <DialogTitle>Select categories</DialogTitle>
                  <DialogContent>
                    <Box
                      component="form"
                      sx={{ display: "flex", flexWrap: "wrap" }}
                    >
                      <FormControl sx={{ m: 1, minWidth: 200 }}>
                        <InputLabel id="category-select-label">
                          Category*
                        </InputLabel>
                        <Select
                          labelId="category-select-label"
                          id="category-select"
                          name="categoriesSelected"
                          value={selectedCategory}
                          onChange={handleCategoryChange}
                          input={<OutlinedInput label="Category" />}
                        >
                          {categories.map((category) => (
                            <MenuItem
                              key={category._id}
                              value={category._id}
                              onClick={() => saveCatId(category._id)}
                            >
                              {category.categoryName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="subcat-select-label">
                          Sub-Category*
                        </InputLabel>
                        <Select
                          labelId="subcat-select-label"
                          id="subcat-select-checkbox"
                          multiple
                          value={selectedSubcategories}
                          onChange={handleSubcategoryChange}
                          input={<OutlinedInput label="Sub-Category" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {subcategories.map((subcategory) => (
                            <MenuItem
                              key={subcategory._id}
                              value={subcategory.subcategoryName}
                              className="menuItemVendor"
                            >
                              {/* <div key={subcategory._id}> */}
                              <input
                                type="checkbox"
                                id={subcategory._id}
                                checked={
                                  selectedSubcategories.indexOf(
                                    subcategory.subcategoryName
                                  ) > -1
                                }
                                onChange={(e) =>
                                  saveSubcatsHandler(e, subcategory._id)
                                }
                              />
                              <label htmlFor={subcategory._id}>
                                {subcategory.subcategoryName}
                              </label>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleCategoriesSelection}>Ok</Button>
                  </DialogActions>
                </Dialog>

                <div className="inputBox">
                  <label htmlFor="businessYears">Years in Business*</label>
                  <input
                    type="number"
                    id="businessYears"
                    value={businessYears}
                    name="businessYears"
                    required
                    onChange={(e) => setBusinessYears(e.target.value)}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="projectsNum">
                    No. of projects till date*
                  </label>
                  <input
                    type="number"
                    id="projectsNum"
                    value={projectsNum}
                    name="projectsNum"
                    required
                    onChange={(e) => setProjectsNum(e.target.value)}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="email">Email*</label>
                  <input
                    type="text"
                    id="email"
                    value={email}
                    name="email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="Mobile Number">Mobile Number*</label>
                  <input
                    type="number"
                    id="Mobile Number"
                    value={phoneNumber}
                    name="phoneNumber"
                    required
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="whatsapp Number">Whatsapp Number*</label>
                  <input
                    type="number"
                    id="whatsapp Number"
                    value={whatsappNumber}
                    name="phoneNumber"
                    required
                    onChange={(e) => setWhatsappNumber(e.target.value)}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="website url">WebSite Url</label>
                  <input
                    type="text"
                    id="website url"
                    value={websiteURL}
                    name="websiteURL"
                    // required
                    onChange={(e) => setWebsiteURL(e.target.value)}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="Facebook page link">Facebook page link</label>
                  <input
                    type="text"
                    id="Facebook page link"
                    value={fbLink}
                    name="fbLink"
                    // required
                    onChange={(e) => setFbLink(e.target.value)}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="Instagram page link">
                    Instagram page link
                  </label>
                  <input
                    type="text"
                    id="instaLink"
                    value={instaLink}
                    name="instaLink"
                    // required
                    onChange={(e) => setInstaLink(e.target.value)}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="GST Number">GST Number</label>
                  <input
                    type="text"
                    id="GST Number"
                    value={gstNumber}
                    name="gstNumber"
                    onChange={(e) => setGstNumber(e.target.value)}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="password">Password*</label>
                  <input
                    type="password"
                    id="password"
                    value={password}
                    name="password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="password">Confirm Password*</label>
                  <input
                    type="password"
                    id="password"
                    value={confirmPassword}
                    name="confirmPassword"
                    required
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <div className="nextButtonDiv">
                  <button className="nextButton" onClick={planFormHandler}>
                    Next
                  </button>
                </div>
              </div>
            </>
          )}
          {/* FORM PLAN SELECTION PART */}
          {planForm && <Plans planSelect={planSelectHandler} />}

          {/* FORM SECOND PART */}

          {selectedPlan === "premium" && (
            <>
              <h1 className="inputBox" style={{ textAlign: "center" }}>
                Upload Content
              </h1>
              <hr />
              <p style={{ color: "grey", fontStyle: "italic" }}>
                You can upload images upto 2MB and videos upto 10MB each.
              </p>
              <div className="secondForm">
                <div className="inputBox">
                  <label htmlFor="startingPrice">Starting price*</label>
                  <input
                    type="number"
                    id="startingPrice"
                    value={startingPrice}
                    name="startingPrice"
                    required
                    onChange={(e) => setStartingPrice(e.target.value)}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="maximumPrice">Maximum price*</label>
                  <input
                    type="number"
                    id="maximumPrice"
                    value={maximumPrice}
                    name="maximumPrice"
                    required
                    onChange={(e) => setMaximumPrice(e.target.value)}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="landscapeImage">Upload Image 1*</label>
                  <input
                    type="file"
                    className="fileInput"
                    id="Image1"
                    required
                    name="image1"
                    onChange={handleFileChange1}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="firstImage">Upload Image 2*</label>
                  <input
                    type="file"
                    className="fileInput"
                    id="firstImage"
                    name="image2"
                    required
                    onChange={handleFileChange2}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="secondImage">Upload Image 3*</label>
                  <input
                    type="file"
                    className="fileInput"
                    id="secondImage"
                    name="image3"
                    required
                    onChange={handleFileChange3}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="thirdImage">Upload Image 4*</label>
                  <input
                    type="file"
                    className="fileInput"
                    id="thirdImage"
                    name="image4"
                    required
                    onChange={handleFileChange4}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="video1">Upload video 1*</label>
                  <input
                    type="file"
                    className="fileInput"
                    id="video1"
                    name="video1"
                    required
                    onChange={handleFileChange5}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="video2">Upload video 2*</label>
                  <input
                    type="file"
                    className="fileInput"
                    id="video2"
                    name="video2"
                    required
                    onChange={handleFileChange6}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="overview">Overview*</label>
                  <textarea
                    id="overview"
                    rows={4}
                    cols={40}
                    value={overview}
                    name="overview"
                    required
                    onChange={(e) => setOverview(e.target.value)}
                  ></textarea>
                </div>
                <div className="inputBox">
                  <label htmlFor="additionalInfo">
                    Additional Information*
                  </label>
                  <textarea
                    id="additionalInfo"
                    rows={4}
                    cols={40}
                    value={additionalInfo}
                    name="additionalInfo"
                    required
                    onChange={(e) => setAdditionalInfo(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="bottomSubmitBar">
                <button type="submit" className="vendorSubmit">
                  Proceed to payment
                </button>
              </div>
              {/* <div className="noteToVendor">
                <p>
                  Thank you for submitting your details. We aim to process and
                  approve registrations within 24 to 48 hours, upon receiving
                  payment. <br />
                  We appreciate your patience and look forward to welcoming you
                  as an approved vendor.
                </p>
              </div> */}
            </>
          )}

          {selectedPlan === "basic" && (
            <>
              <h1 style={{ textAlign: "center" }}>Other Details</h1>
              <hr />
              <div className="basicPlanDiv">
                {/* <div className="priceRangeDiv"> */}
                <div className="inputBox">
                  <label htmlFor="startingPrice">Starting price*</label>
                  <input
                    type="number"
                    id="startingPrice"
                    value={startingPrice}
                    name="startingPrice"
                    required
                    onChange={(e) => setStartingPrice(e.target.value)}
                  />
                </div>
                <div className="inputBox">
                  <label htmlFor="maximumPrice">Maximum price*</label>
                  <input
                    type="number"
                    id="maximumPrice"
                    value={maximumPrice}
                    name="maximumPrice"
                    required
                    onChange={(e) => setMaximumPrice(e.target.value)}
                  />
                </div>
                {/* </div> */}
                {/* <div className="lastDiv"> */}
                <div className="inputBox">
                  <label htmlFor="overview">Overview*</label>
                  <textarea
                    id="overview"
                    rows={4}
                    cols={40}
                    value={overview}
                    name="overview"
                    required
                    onChange={(e) => setOverview(e.target.value)}
                  ></textarea>
                </div>
                <div className="inputBox">
                  <label htmlFor="additionalInfo">
                    Additional Information*
                  </label>
                  <textarea
                    id="additionalInfo"
                    rows={4}
                    cols={40}
                    value={additionalInfo}
                    name="additionalInfo"
                    required
                    onChange={(e) => setAdditionalInfo(e.target.value)}
                  ></textarea>
                </div>
                {/* </div> */}
                {/* </div> */}
              </div>
              <div className="bottomSubmitBar">
                <button type="submit" className="vendorSubmit">
                  Proceed to payment
                </button>
              </div>
              {/* <div className="noteToVendor">
                <p>
                  Thank you for submitting your details. We aim to process and
                  approve registrations within 24 to 48 hours, upon receiving
                  payment. <br />
                  We appreciate your patience and look forward to welcoming you
                  as an approved vendor.
                </p>
              </div> */}
            </>
          )}
        </form>
        <ToastContainer />
      </div>
    </>
  );
}

export default FirstForm;
